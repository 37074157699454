import { put, call } from 'redux-saga/effects';
import { get } from "lodash";
import { setPageLoader, setFlashMessage } from "../ui/actions";
import { callApi } from "../../../helpers/boda-boda/common-helpers/callApi.helpers";
import { getPackageListAPI, getManifestListAPI } from "../../../config/boda-boda/apiUrls.config";
import { resetDataTable } from "../core/actions";

const emptyFn = (...para) => undefined;

function* updateManifestId(data) {

    yield put(setPageLoader(true));

    let response = yield call(() => callApi(`${getPackageListAPI.url}/${data.id}`)
        .headers(true)
        .method("put")
        .body(data.payload)
        .send()
    );

    if (response._statue === true) {
        yield put(resetDataTable(`${getPackageListAPI.key}_manifest`));
        yield put(setPageLoader(false));
        yield put(setFlashMessage(data.messageObj));
    } else {
        yield put(setPageLoader(false));
        if (get(response, "data.statusCode", 0) === 403) {
            yield put(setFlashMessage({
                status: true,
                message: "Permission denied to access",
                type: "danger"
            }));
        } else if (get(response, "data.statusCode", 0) === 400) {
            yield put(setFlashMessage({
                status: true,
                message: get(response, 'data.message', 'Something went wrong!'),
                type: "danger"
            }));
        } else {
            yield put(setFlashMessage({
                status: true,
                message: "Something went wrong!",
                type: "danger"
            }));
        }
    } 
}

function* redraftManifest(data) {
    yield put(setPageLoader(true));

    const response = yield call(() => callApi(getManifestListAPI.url)
        .headers(true)
        .method("post")
        .body({
            "pickAt": get(data, "payload.pickAt"),
            "locationPick": {
                "id": get(data, "payload.locationPick"),
            },
            "status": "DRAFT",
            "type": "MERCHANT",
            "notes": []
        })
        .send()
    );

    if (response._statue === true) {
        const redraftResponse = yield call(() => callApi(`${getManifestListAPI.url}/${get(data, "payload.oldId")}/package`)
            .headers(true)
            .method("put")
            .body({
                "manifest": {
                    "id": get(response, "data.data.id"),
                }
            })
            .send()
        );

        if (redraftResponse._statue === true) {
            get(data, "payload.history.push", emptyFn)(`/pick-request/drafts/edit/${get(response, "data.data.id")}`);
            yield put(setPageLoader(false));
            yield put(setFlashMessage({
                status: true,
                message: "Pick Request has successfully redrafted",
                type: "success"
            }));
        }
    }

    yield put(setPageLoader(false));
}

export {
    updateManifestId,
    redraftManifest
}
